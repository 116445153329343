
import { CallCheckListModel } from "@/services/types";
import Vue from "vue";
import Component from "vue-class-component";
import CollapseArea from "./CollapseArea.vue";
import { StoreActionTypes, StoreMutationTypes } from "@/store/types";
import {getStore} from "@/store/getStore";

interface CallCheckListRenderItem extends CallCheckListModel {
  state: boolean;
}

@Component({
  components: {
    CollapseArea,
  },
})
export default class CallCheckList extends Vue {
  private componentClass = "CallCheckList";

  private value: number | null = null;
  private renderList: CallCheckListRenderItem[] = [];

  get estimatedChecklistId() {
    return  getStore().getters.getSelectedCallEstimatedCheckList?.checklistId
  }

  async mounted() {
    const list = this.$store.getters
      .getSelectedCallChecklists as CallCheckListModel[];

    this.renderList = list.map((item) => {
      return {
        ...item,
        state: item.default,
      };
    });

    const id = this.$store.getters.getSelectedCallCheckListId;

    console.log(id, this.renderList);

    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_FULL_LIST_GET,
      id ? id : this.renderList[0].id
    );
  }

  async onClick(val: number) {
    this.value = val;
    this.renderList.map((item) => {
      item.id === this.value ? (item.state = true) : (item.state = false);
    });

    this.$store.commit(
      StoreMutationTypes.SET_SELECTED_CALL_CURRENT_ESTIMATION_CLEAR,
      []
    );
    this.$emit("load");

    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_FULL_LIST_GET,
      this.value
    );
    await this.$store.dispatch(
      StoreActionTypes.SET_SELECTED_CALL_CATEGORIES,
      this.value
    );
  }
}
