
import Vue, { PropType } from "vue";
import Component from "vue-class-component";
import * as utils from "@/services/utils";
import {
  BaseEntityModel,
  CallLeadModel,
  SelectModel,
  SetCallManagerRequestModel,
} from "@/services/types";
import { StoreActionTypes } from "@/store/types";
import DefaultSelect from "@/components/lib/select/DefaultSelect.vue";
import { i18n } from "@/i18n/index";
import { getStore } from "@/store/getStore";
import { DetailedCallEntityModel } from "@/services/types/entities/entities";
import { isUserHasPermission } from "@/services/roles";
import { UserPermissions } from "@/services/roles/permissionsEnum";
import { getEbkReasonCustomerLabel } from "@/components/callsUpload/reasonCustomer";
import {formatDateTimeString} from "@/services/utils";
import EbkLeadDecisionDisplay from "@/features/ebk/EbkLeadDecisionDisplay.vue";

const ICallDetailsMainInfoProps = Vue.extend({
  props: {
    closeListener: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
});

@Component({
  methods: { getEbkReasonCustomerLabel },
  components: {
    EbkLeadDecisionDisplay,
    DefaultSelect,
  },
})
export default class CallDetailsMainInfo extends ICallDetailsMainInfoProps {
  private componentClass = "CallDetailsMainInfo";

  get getCall() {
    return getStore().getters
      .getSelectedCall as unknown as DetailedCallEntityModel;
  }

  get getTime() {
    return this.$store.getters.getSelectedCall
      ? utils.renderDateFromString(
          this.$store.getters.getSelectedCall.startedAt
        )
      : "";
  }

  get getProjectName() {
    return this.getCall?.project.name || "";
  }

  get getSource() {
    return this.getCall?.data.source || "";
  }

  get getManager() {
    return this.getCall.manager.id.toString() || "";
  }

  get getPhone() {
    return this.getCall?.data.phone || "";
  }

  get getLead(): CallLeadModel {
    return this.getCall
      ? {
          id: this.getCall.data.externalLinkId,
          link: this.getCall.data.externalLink,
        }
      : {
          id: null,
          link: "",
        };
  }

  get availableManagersList(): SelectModel[] {
    return (
      this.$store.getters
        .getSelectedCallAvailableManagersList as BaseEntityModel[]
    ).map((item) => ({ value: item.id.toString(), text: item.name }));
  }

  get getTitle(): string {
    switch (this.getCall?.data.direction || "") {
      case "incoming":
        return i18n.t("calls.details.title.incoming") as string;
      case "outgoing":
        return i18n.t("calls.details.title.outgoing") as string;
      default:
        return "";
    }
  }

  handleClientPhoneClick() {
    this.$store.dispatch(
      StoreActionTypes.SET_CALLS_LIST_LINKED_PHONE_FILTER,
      this.getPhone
    );
    this.closeListener();
  }

  async onManagersSelectChange(selected: string) {
    const model: SetCallManagerRequestModel = {
      id: parseInt(selected),
    };

    await this.$store.dispatch(
      StoreActionTypes.SET_SELECTED_CALL_SET_MANAGER,
      model
    );
  }

  get getManagersSelectDisabled(): boolean {
    return (
      this.$store.getters.isSelectedCallDataLoading ||
      !isUserHasPermission(UserPermissions.CHANGE_COMPANY_ENTITIES_MANAGER)
    );
  }

  get getDistributedAt() {
    return this.getCall.data[`distributedAt`]
        ? utils.formatDateTimeString(
            this.getCall.data[`distributedAt`],
            true
        )
        : "";
  }

  get getLeadDecision() {
    switch (this.getCall.data[`leadDecision`]) {
      case true:
        return 'Одобрено'
      case false:
        return 'Не одобрено'
      default:
        return undefined
    }
  }

  get getContractId() {
    const id = this.getCall.data[`contractId`]
    return id ? id : undefined
  }
}
